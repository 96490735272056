import React from "react";
import styled from "styled-components";
import Braces from "../braces";
import Interface from "../interface";

const Wrapper = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const Name = styled.span`
  color: ${props => props.theme.code.projectColor};
  font-size: 1.2rem;
`;

const Project = (props) => {
  const braceHead = <>
    <Name>{props.name}</Name>
    <Interface>{props.tech}</Interface>
  </>

  return <Wrapper>
    <Braces headComponent={braceHead} collapsed={true}>
      {props.description}
    </Braces>
  </Wrapper>;
};

export default Project;
