import React from "react";
import styled from "styled-components";

const StyledInterface = styled.span`
  color: ${props => props.theme.code.interfaceColor};
  font-size: .9rem;
`;
const Colon = styled.span`
  margin-left: .5em;
  margin-right: .5em;
`;

const Interface = ({ children }) => (
  <StyledInterface>
    <Colon>:</Colon>
    <span>{children}</span>
  </StyledInterface>
);

export default Interface;
