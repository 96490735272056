const organizations = [
  {
    name: "BenchSci",
    location: "Toronto, ON",
    industry: "AI in Biomedical",
    projects: [],
    roles: [
      {
        expanded: true,
        name: "Senior Software Engineer II",
        timeFrame: "April 2023 - Present",
        responsibilities: [
          "Designed, led, and heavily contributed to an LLM-powered Search feature, being our first LLM-based offering to biomedical industry users, which improved usability by simplifying how users can search across apps",
          "Investigated on-the-fly evaluation of RAG components and delivered an open-source contribution to llama-index that enables developers to easily attach evaluators to query engines",
        ],
      },
      {
        expanded: true,
        name: "Senior Software Engineer I",
        timeFrame: "May 2022 - April 2022",
        responsibilities: [
          "Successfully increased customer re-engagement rates by designing and implementing a Data Notifications feature that alerts scientists when their searches for reagents of interest contain new data, all while collaborating with scientists, data analysts, UX researchers, and other teams",
          "Actively contributed to a Share Search feature while mentoring others, allowing scientists to share searches for reagents, increasing collaboration between scientists and providing capabilities to the Data Notifications and LLM-powered Search features",
          "Improved productivity and increased velocity by writing and maintaining onboarding guides for software engineers on how to setup local development environments for running and testing or apps with debugging capabilities",
        ],
      },
    ],
  },
  {
    name: "Chisel AI",
    location: "Toronto, ON",
    industry: "AI in Insurance",
    projects: [
      {
        name: "Document Ingestion Pipeline",
        tech: "Terraform, AWS (API Gateway, Lambda, Neptune, SNS, SQS), Python, Distribuited Computing, PubSub",
        description: "A highly-parallelized pipeline built on serverless architecture that accepts documents and metadata for performing insurance-based entity recognition and infernece."
      },
      {
        name: "Policy Check",
        tech: "Terraform, AWS (Cognito, Neptune, ECS), Python, React.js",
        description: "An AI-based solution that greatly reduces the time and effort involved in comparing sets of insurance papers across a multitude of formats."
      },
      {
        name: "Submission Intake",
        tech: "Terraform, AWS (Cognito, Neptune, ECS), Python, React.js",
        description: "An AI-based solution that aims to automate submission intake for carriers, improving submission throughput and accelerating time to revenue."
      }
    ],
    roles: [
      {
        expanded: true,
        name: "Software Developer",
        timeFrame: "September 2020 - April 2022",
        responsibilities: [
          "Worked closely with a team of Software Developers, Machine Learning Scientists, and Product Owners to deliver AI-based solutions to the insurance industry",
          "Co-developed a service registry for orchestrating a set of Machine Learning models to run against a particular data artifact. This allowed our Machine Learning Scientists to rapidly iterate on models, with little engineering lift",
          "Successfully designed and implemented a schema-based solution for tracking transaction completion, which allowed Chisel's two development teams to achieve full decoupling and autonomy, and increase our operational excellence",
          "Developed an efficient Terraform module breakdown which reduced the onboarding time of net new customers from 2 weeks to 2-3 days",
          "Designed and introduced a pattern for serializing customer transactions into our AWS Neptune instance, which led to an increase in write speed, lower computational costs, and a reduction of customer transaction time from 45 minutes to less than 10 minutes. This allowed our team to clear POC milestones and move our product into Production with a major client ($250k in ARR)"
        ]
      }
    ]
  },
  // Block 64 
  {
    name: "Block 64 Corporation",
    location: "Toronto, ON",
    industry: "IT Asset Discovery",
    projects: [
      {
        name: "Native Windows App",
        tech: "Electron, React.js, C#, Python, TCP/IP",
        description: "An agentless discovery tool built as a native Windows application. Able to inventory Windows devices, vCenter/ESXi environments, Active Directory, and resource consumption statistics. Designed to be easy to deploy and configure by the end-user, it just works."
      },
      {
        name: "BlockBox Virtual Appliance",
        tech: "LAMP, Python, TCP/IP, Distributed Computing",
        description: "Another agentless discovery tool. As you may have guessed, deployable as a virtual appliance. Abilities include inventorying Windows, Linux, Mac, SNMP, VMware, and Nutanix environments. It also contains many of the reporting features of the Analytics Portal."
      },
      {
        name: "Analytics Portal",
        tech: "LAMP, Python, AWS, Azure, TCP/IP, Distributed Computing",
        description: "A cloud-based application that provides end-users deep, analytical insight of their data through an authenticated session. Data residency is supported in a multi-region and multi-cloud fashion. A wide range of reports are offered, covering topics like cyber-security and cloud migration."
      }
    ],
    roles: [
      {
        name: "Manager of Product Development",
        timeFrame: "May 2019 - August 2020",
        responsibilities: [
          "Managing a team of software engineers, playing the roles of SCRUM master, product owner, and lead developer",
          "Implemented SCRUM framework of the Agile software development lifecycle when there was no concrete SDLC model in place",
          "Prioritized and managed the implementation of CI/CD pipelines",
          "Enabled support team to process end-user requests by implementing a process with Zendesk and integrating it with Jira Cloud"
        ]
      },
      {
        name: "Product Development Supervisor",
        timeFrame: "May 2018 - May 2019",
        responsibilities: [
          "Designed, developed, and managed the release of technical solutions to meet the company’s goals",
          "Led the development of a Windows application used to discover and inventory devices on a network, built with .NET Core, Electron, and React.js",
          "Continued involvement in all facets of the products including cloud infrastructure, the development of core inventory routines, and an enterprise SaaS application",
        ]
      },
      {
        name: "Software Engineer",
        timeFrame: "December 2015 - May 2018",
        responsibilities: [
          "Designed, developed, and shipped features and enhancements for enterprise-level software",
          "Optimized algorithms and processes intended to work on a large scale through database optimization and distributed computing",
          "Integral in migration of cloud infrastructure to AWS from a previous service provider"
        ]
      }
    ]
  },

  // Propeller
  {
    name: "Propeller Studios Inc.",
    location: "Toronto, ON",
    industry: "Marketing and Branding",
    roles: [
      {
        name: "Full Stack Web Developer",
        timeFrame: "July 2015 - November 2015",
        responsibilities: [
          "Developed web applications using the LAMP stack and CakePHP framework",
          "Built scalable plugins and components for CakePHP applications",
          "Created HTML5 ads and email templates",
          "Handled site migration and backups from server to server"
        ]
      }
    ]
  }
];

export default organizations;
