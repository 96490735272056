import React, { useState } from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
`;

const Head = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const CollapseHandle = styled.div`
  position: relative;
`;

const CollapseHandleWrapper = styled.div`
  display: inline;
  height: 100%;
  width: 100%;
  font-size: 1.1rem;
  margin-right: .5em;
  color: ${props => props.theme.code.blockCollapseColor};
  border: solid 1px rgb(0, 0, 0, 0);
  cursor: pointer;

  &:hover,
  &:focus {
    transition: .5s;
    border: solid 1px;
    text-shadow: 0 0 1px;
    box-shadow: 0 0 5px;
  }
`;

const CodeWrapper = styled.div`
  margin-left: .5em;
`;

const Code = styled.div`
  color: ${props => props.theme.code.codeColor};
  border-left: solid 1px ${props => props.theme.code.blockLineColor};
  padding-left: .5rem;
  font-size: 1.1rem;
`;

const Brace = styled.span`
  display: block;
  position: relative;
  color: ${props => props.theme.code.braceColor};
  font-size: 1.1rem;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    height: 100%;
    border-left: solid 1px ${props => props.theme.code.blockLineColor};
    border-top-left-radius: 5px;
  }
`;

const OpenBrace = styled(Brace)`
  &::before {
    top: 50%;
  }
  &::after {
    content: '{';
  }
`;

const CloseBrace = styled(Brace)`
  &::before {
    bottom: 50%;
  }
  &::after {
    content: '}';
  }
`;

const Braces = (props) => {
  const [collapsed, setCollapse] = useState(props.collapsed ? true : false);

  const toggleCollapse = () => {
    setCollapse(!collapsed);
  };

  const collapseHandle = (collapsed ? "+" : "-");

  return <Wrapper>

    <Section>

      <div>
        <CollapseHandle onClick={toggleCollapse}>
          <CollapseHandleWrapper>
            [{collapseHandle}]
          </CollapseHandleWrapper>
        </CollapseHandle>
      </div>

      <Head>
        {props.headComponent}
        {
          collapsed &&
          <>
            <OpenBrace />
            <CloseBrace />
          </>
        }
      </Head>
    </Section>

    <Section>
      <div />
      <CodeWrapper>
        {
          !collapsed &&
          <>
            <OpenBrace />
            <Code>{props.children}</Code>
            <CloseBrace />
          </>
        }
      </CodeWrapper>
    </Section>
  </Wrapper>
}

export default Braces;
