import React from "react";
import styled from "styled-components";
import Braces from "../braces";

const Wrapper = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const Name = styled.div`
  color: ${props => props.theme.code.roleColor};
  font-size: 1.2rem;
`;

const TimeFrame = styled.span`
  color: ${props => props.theme.code.roleTimeFrameColor};
`;

const Responsibility = styled.div`
  margin-bottom: .5em;
`;

const Semicolon = styled.span`
  color: ${props => props.theme.code.semicolonColor};
  content: ";"
`;

const Role = (props) => {
  const braceHead = <>
    <Name>{props.name} </Name>
    (<TimeFrame>{props.timeFrame}</TimeFrame>)
  </>

  return <Wrapper>
    <Braces headComponent={braceHead} collapsed={!props.expanded}>
      {
        props.responsibilities.map((responsibility) => {
          return <Responsibility>
            {responsibility}
            <Semicolon>;</Semicolon>
          </Responsibility>;
        })
      }
    </Braces>
  </Wrapper>;
};

export default Role;
