import React from "react";
import styled from "styled-components";
import Organization from "../components/experience/organization";
import organizations from "../data/organizations";
import Section from "../components/section";
import SECTIONS from "../constants/sections";

const OrganizationsWrapper = styled.div`
  margin: auto;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  @media(min-width: ${props => props.theme.body.contentWidth}) {
    width: ${props => props.theme.body.contentWidth};
  }
`;

const Experience = () => {
  return <Section name={SECTIONS.experience.label} seo={SECTIONS.experience.seo}>
    <OrganizationsWrapper>
      {
        organizations.map((organization) => {
          return <Organization {...organization} />;
        })
      }
    </OrganizationsWrapper>
  </Section>;
};

export default Experience;
