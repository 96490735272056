import React from "react";
import styled from "styled-components";
import Project from "./project";
import Role from "./role";
import Braces from "../braces";
import Interface from "../interface";

const Wrapper = styled.div`
  text-shadow: 0px 0px 1px;
  padding-top: 1em;
  padding-bottom: 1em;
`;

const OrganizationName = styled.span`
  font-size: 1.5rem;
  color: #ff56ed;
  text-shadow: 0px 0px 1px;
`;

const CodeSection = styled.div`
  padding: 0.5rem;
`;

const RolesSyntax = styled.span`
  font-size: 1.2rem;
  color: ${props => props.theme.code.rolesColor};
`;

const ProjectsSyntax = styled.span`
  font-size: 1.2rem;
  color: ${props => props.theme.code.projectsColor};
`;


const Organization = (props) => {
  const braceHead = <>
    <OrganizationName>{props.name}</OrganizationName>
    <Interface>{props.industry + ", " + props.location}</Interface>
  </>;

  return <Wrapper>

    <Braces headComponent={braceHead} collapsed={false}>

      <CodeSection>
        <RolesSyntax>roles</RolesSyntax> :
        {
          props.roles.map((role) => {
            return <Role {...role} />;
          })
        }
      </CodeSection>

      {
        props.projects &&
        <CodeSection>
          <ProjectsSyntax>projects</ProjectsSyntax> :
          {
            props.projects.map((project) => {
              return <Project {...project} />;
            })
          }
        </CodeSection>
      }

    </Braces>
  </Wrapper>;
};

export default Organization;
